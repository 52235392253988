<template>
  <div :class="customClass" class="relative dashed_border w-full p-[1rem] h-full rounded-[0.5rem] bg-[#FBFCFD]">
    <button type="button" :disabled="disabled" @click="$emit('click', '')" v-if="image && image.length && image.length > 8" class="absolute mr-3 z-40 cursor-pointer right-0">
      <svg xmlns="http://www.w3.org/2000/svg" class="w-[1rem] h-[1rem]" viewBox="0 0 16 18" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.80735 2.02289C5.96582 1.86442 6.18076 1.77539 6.40487 1.77539H9.5949C9.81901 1.77539 10.0339 1.86442 10.1924 2.02289C10.3509 2.18136 10.4399 2.39629 10.4399 2.62041V3.46445H5.55985V2.62041C5.55985 2.39629 5.64888 2.18136 5.80735 2.02289ZM4.7713 4.96445C4.78407 4.96509 4.79692 4.96542 4.80985 4.96542C4.82278 4.96542 4.83564 4.96509 4.8484 4.96445H11.1514C11.1641 4.96509 11.177 4.96542 11.1899 4.96542C11.2028 4.96542 11.2157 4.96509 11.2285 4.96445H12.8324V15.3805C12.8324 15.6046 12.7434 15.8196 12.5849 15.978C12.4265 16.1365 12.2115 16.2255 11.9874 16.2255H4.01235C3.78823 16.2255 3.5733 16.1365 3.41483 15.978C3.25636 15.8196 3.16733 15.6046 3.16733 15.3805V4.96445H4.7713ZM4.05985 3.46445V2.62041C4.05985 1.99847 4.30692 1.40201 4.74669 0.96223C5.18647 0.522454 5.78293 0.275391 6.40487 0.275391H9.5949C10.2168 0.275391 10.8133 0.522454 11.2531 0.96223C11.6929 1.40201 11.9399 1.99847 11.9399 2.62041V3.46445H15.1774C15.5916 3.46445 15.9274 3.80023 15.9274 4.21445C15.9274 4.62866 15.5916 4.96445 15.1774 4.96445H14.3324V15.3805C14.3324 16.0025 14.0854 16.5989 13.6456 17.0387C13.2058 17.4785 12.6094 17.7255 11.9874 17.7255H4.01235C3.39041 17.7255 2.79394 17.4785 2.35417 17.0387C1.91439 16.5989 1.66733 16.0025 1.66733 15.3805V4.96445H0.822266C0.408052 4.96445 0.0722656 4.62866 0.0722656 4.21445C0.0722656 3.80023 0.408052 3.46445 0.822266 3.46445H2.41728H4.05985ZM6.40472 7.45238C6.81894 7.45238 7.15472 7.78816 7.15472 8.20238V12.9874C7.15472 13.4016 6.81894 13.7374 6.40472 13.7374C5.99051 13.7374 5.65472 13.4016 5.65472 12.9874V8.20238C5.65472 7.78816 5.99051 7.45238 6.40472 7.45238ZM10.3449 8.20238C10.3449 7.78816 10.0091 7.45238 9.59485 7.45238C9.18064 7.45238 8.84485 7.78816 8.84485 8.20238V12.9874C8.84485 13.4016 9.18064 13.7374 9.59485 13.7374C10.0091 13.7374 10.3449 13.4016 10.3449 12.9874V8.20238Z" fill="#97304A"/>
      </svg>
    </button>
    <div v-if="!image || (image.length == 8 && image.includes('https://'))" class="h-full flex flex-col justify-center items-center">
      <div class="flex-1 flex items-center justify-center">
        <div class="flex gap-x-3 items-center justify-center">
          <input
            id="custom_meta_image"
            placeholder="Enter the meta image url"
            type="file"
            @change="uploadMetaImage($event)"
            :disabled="disabled"
            accept=".png, .jpg, .jpeg, .gif"
            class="d-none">
          <label v-if="!uploadLoader" class="flex cursor-pointer gap-x-2" for="custom_meta_image">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" viewBox="0 0 25 23" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9698 0.557561C12.2627 0.264667 12.7376 0.264667 13.0305 0.557561L17.997 5.52405C18.2899 5.81694 18.2899 6.29182 17.997 6.58471C17.7041 6.8776 17.2292 6.8776 16.9363 6.58471L13.25 2.89841V13.3638C13.25 13.778 12.9142 14.1138 12.5 14.1138C12.0858 14.1138 11.75 13.778 11.75 13.3638V2.89869L8.06398 6.58471C7.77109 6.8776 7.29621 6.8776 7.00332 6.58471C6.71043 6.29182 6.71043 5.81694 7.00332 5.52405L11.9698 0.557561ZM1.32544 13.714C1.73965 13.714 2.07544 14.0498 2.07544 14.464V19.4305C2.07544 19.8902 2.25805 20.3311 2.5831 20.6561C2.90814 20.9812 3.349 21.1638 3.80868 21.1638H21.1914C21.6511 21.1638 22.0919 20.9812 22.417 20.6561C22.742 20.3311 22.9246 19.8902 22.9246 19.4305V14.464C22.9246 14.0498 23.2604 13.714 23.6746 13.714C24.0889 13.714 24.4246 14.0498 24.4246 14.464V19.4305C24.4246 20.288 24.084 21.1104 23.4776 21.7168C22.8713 22.3231 22.0489 22.6638 21.1914 22.6638H3.80868C2.95117 22.6638 2.12879 22.3231 1.52243 21.7168C0.916084 21.1104 0.575439 20.288 0.575439 19.4305V14.464C0.575439 14.0498 0.911226 13.714 1.32544 13.714Z" fill="#2961D2"/>
            </svg>
            <p>
              <span class="font-poppins text-[0.75rem] text-[#757A8A] font-bold mr-1">Choose an Image</span> <span class="text">or drag it here</span>
            </p>
          </label>
          <Loader v-if="uploadLoader" color="text-[#2560d7]"></Loader>
        </div>
      </div>
    </div>
    <div class="flex flex-1 h-full flex-col items-center justify_center" v-else>
      <img :src="image" class="h-full"/>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  props: ['customClass', 'image', 'disabled'],
  data () {
    return {
      url: '',
      uploadLoader: false
    }
  },
  components: {
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue')
  },
  methods: {
    ...mapActions(['uploadPasswordProtectedLogo']),
    async uploadMetaImage (event) {
      this.uploadLoader = true
      this.url = await this.uploadPasswordProtectedLogo(event)
      this.uploadLoader = false
      this.$emit('click', this.url)
    }
  }
}
</script>
